// IE9 placeholder fallback from http://webdesignerwall.com/tutorials/cross-browser-html5-placeholder-text
// Dependent on Modernizer
function placeholderFallback() {

    if(!Modernizr.placeholder){
        $('[placeholder]').focus(function() {
            var input = $(this);
            if (input.val() == input.attr('placeholder')) {
                input.val('');
                input.removeClass('placeholder');
            }
            }).blur(function() {
                var input = $(this);
                if (input.val() == '' || input.val() == input.attr('placeholder')) {
                    input.addClass('placeholder');
                    input.val(input.attr('placeholder'));
                }
            }).blur();
            $('[placeholder]').parents('form').submit(function() {
            $(this).find('[placeholder]').each(function() {
                var input = $(this);
                if (input.val() == input.attr('placeholder')) {
                    input.val('');
                }
            })
        });
    }

}

function attachSelectric() {

    $('.js-styled-select').selectric().on('change', function() {

        var $this = $(this),
            $selectric = $this.parents('.selectric-wrapper').find('.selectric');


        if($this.val() == '')
        {
            $selectric.removeClass('is-active');
        }
        else
        {
            $selectric.addClass('is-active');
        }
    });

}

$(function() {

    placeholderFallback();

    attachSelectric();

    $(document).on('change', '.js-styled-radio', function(){
        var $this = $(this),
            $wrapper = $this.parents('.radio-group'),
            $inputs = $wrapper.find('.js-styled-radio');

        $inputs.each(function(){
            var $input = $(this),
                $label = $input.parents('.radio-label');
            if($input.is(':checked'))
            {
                $label.addClass('is-active');
            }
            else
            {
                $label.removeClass('is-active');
            }
        });

    });

    $(document).on('change', '.js-styled-checkbox', function(){
        var $this = $(this),
            $label = $this.parents('.checkbox-label');


        if($this.is(':checked'))
        {
            $label.addClass('is-active');
        }
        else
        {
            $label.removeClass('is-active');   
        }

    });

});