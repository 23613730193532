$(function() {

    // attach magnific popup
    $(document).on('click', '.js-popup', function(e){
        e.preventDefault();
        $this = $(this);
        $.magnificPopup.open({
            items : {src : $this.attr('href')},
            type : "ajax",
            removalDelay: 500,
            closeOnBgClick : false,
            mainClass: 'mfp-zoom-out',
            callbacks: {
                open: function() {
                    $('body').addClass('popup-open mod-blur');
                },
                close: function() {
                    $('body').removeClass('popup-open mod-blur');
                },
                ajaxContentAdded: function() {
                    placeholderFallback();
                },
            }
        });
    });

    $(document).on('submit', '.js-on-submit-open-popup', function(e){
        e.preventDefault();
        
        var $this = $(this),
            email = $this.find('.mailing-input').val();

        $.magnificPopup.open({
            items : {src : $this.attr('action')},
            type : "ajax",
            removalDelay: 500,
            closeOnBgClick : false,
            mainClass: 'mfp-zoom-out',
            callbacks: {
                open: function() {
                    $('body').addClass('popup-open mod-blur');
                },
                close: function() {
                    $('body').removeClass('popup-open mod-blur');
                },
                ajaxContentAdded: function() {
                    placeholderFallback();
                    $('.popup .email-input').val(email);
                },
            }
        });
    });

});