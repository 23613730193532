$(function(){
	
	$(document).on('click', '.js-toggle-callback-form', function(e){
		e.preventDefault();

		var $this = $(this),
			$form = $this.parents('.callback').find('.callback-main');


		if ($form.is(':visible')) {
			$form.slideUp();
			$this.removeClass('is-open');
		} else {
			$form.slideDown();
			$this.addClass('is-open');
		}

	});

	$(document).on('click', '.js-scroll-to-content', function(e){
		e.preventDefault();


		if (Modernizr.mq('(min-width: 992px)')) {
			
			$("html, body").animate({
				scrollTop: $('.content').offset().top
			}, 600);

		} else {

			$("html, body").animate({
				scrollTop: $('.content').offset().top - $('.header').outerHeight()
			}, 600);

		}

	});

	$(document).on('click', '.js-van-desc-read-more', function(e){
		e.preventDefault();

		var $this = $(this),
			$desc = $this.parents('.van-featured-desc');

		if ($desc.hasClass('is-open')) {

			$this.text('Read More...');

			$desc.removeClass('is-open');

			TweenMax.to($desc, 0.4, {
				height: '8.5rem',
				overflow: 'hidden'
			});

		} else {

			$desc.addClass('is-open');

			$this.text('Read Less');

			TweenMax.set($desc, {
				height:'auto',
				overflow: 'visible'
			});

			TweenMax.from($desc, 0.4, {
				height: '8.5rem',
				overflow: 'hidden'
			});

		}


	});

	$(".js-scroll-to-vehicles").on("click", function(e) {
	    e.preventDefault();

	    $("html, body").animate({
	        scrollTop: $('.content-vehicles').offset().top
	    }, 600);
	    
	});

	$('[data-toggle="tooltip"]').tooltip();
	
});