function toggleSubMenuMobile($subMenu) {

	var $listItem = $subMenu.closest('.nav-list-item');

	if ($subMenu.is(':hidden')) {
		$subMenu.slideDown();
		$listItem.addClass('is-open');
	} else {
		$subMenu.slideUp();
		$listItem.removeClass('is-open');
	}

}

function openSubMenuDesktop($subMenu) {

	TweenMax.to($subMenu, 0.4, {
		display: 'block',
		opacity: 1
	});

	TweenMax.to($('.page-overlay'), 0.4, {
		display: 'block',
		opacity: 1
	});

}

function closeSubMenuDesktop($subMenu) {

	TweenMax.to($subMenu, 0.4, {
		display: 'none',
		opacity: 0
	});

	TweenMax.to($('.page-overlay'), 0.4, {
		display: 'none',
		opacity: 0
	});

}

$(function() {

	// toggle popout navigation sub menus
	$(document).on('click', '.popout-navigation .js-toggle-sub-menu', function(e){
		e.preventDefault();

		var $this = $(this),
			$subMenu = $this.next('.nav-sub');
		
		toggleSubMenuMobile($subMenu);

	});

	// toggle popout navigation sub sub menus
	$(document).on('click', '.popout-navigation .js-toggle-sub-sub-menu', function(e){
		e.preventDefault();

		var $this = $(this),
			$subMenu = $this.next('.nav-sub-sub');
		
		toggleSubMenuMobile($subMenu);

	});

	// desktop navigation events for non touch devices
	if ($('html').hasClass('no-touchevents')) {

		// open sub mmenu on mouse over
		$(document).on('mouseover', '.header-nav .has-children', function(e){

			var $this = $(this),
				$subMenu = $this.find('.nav-sub');

			openSubMenuDesktop($subMenu);

		});

		// open sub mmenu on mouse leave
		$(document).on('mouseleave', '.header-nav .has-children', function(e){

			var $this = $(this),
				$subMenu = $this.find('.nav-sub');

			closeSubMenuDesktop($subMenu);

		});

		// open sub sub mmenu on mouse over
		$(document).on('mouseover', '.header-nav .nav-sub .has-children', function(e){

			var $this = $(this),
				$subMenu = $this.find('.nav-sub-sub');

			openSubMenuDesktop($subMenu);

		});

		// open sub sub mmenu on mouse leave
		$(document).on('mouseleave', '.header-nav .nav-sub .has-children', function(e){

			var $this = $(this),
				$subMenu = $this.find('.nav-sub-sub');

			closeSubMenuDesktop($subMenu);

		});

	// desktop navigation events for touch devices
	} else {

		// open sub menu on click if it isn't already open, otherwise go to links href
		$(document).on('click', '.header-nav .js-toggle-sub-menu', function(e){
			
			e.stopPropagation();
			
			var $this = $(this),
				$subMenu = $this.next('.nav-sub'),
				$menus = $('.header-nav .nav-sub');

			if ($subMenu.is(':hidden')) {

				e.preventDefault();

	            $menus.each(function(){
	            	closeSubMenuDesktop($(this));
	            });

				openSubMenuDesktop($subMenu);

			}


		});

		// open sub sub menu on click if it isn't already open, otherwise go to links href
		$(document).on('click', '.header-nav .js-toggle-sub-sub-menu', function(e){

			e.stopPropagation();
			
			var $this = $(this),
				$subMenu = $this.next('.nav-sub-sub'),
				$menus = $('.header-nav .nav-sub-sub');

			if ($subMenu.is(':hidden')) {

				e.preventDefault();

				$menus.each(function(){
	            	closeSubMenuDesktop($(this));
	            });
				
				openSubMenuDesktop($subMenu);

			}

		});

		//Closes sub menus on touch devices is clicking off navigation
        $(document).on('touchstart', function(e){

            var $menus = $('.header-nav .nav-sub');

            $menus.each(function(){
            	closeSubMenuDesktop($(this));
            });

        });

        // stop menu from closing when clicking inside on touch devices
        $('.header-nav .nav-sub').on('touchstart', function(e) {
            e.stopPropagation();
        });

	}


});