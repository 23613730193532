$(function() {

	// Determine whether the browser supports overflow-scrolling
	function supportsOverflowScrolling() {
		return !($('html').hasClass('no-overflowscrolling'));
	}
	
	// Determine whether a popout is currently active
	function isPopoutsActive() {
		var activePopout = false;
		$(popouts).each(function(index, value) {
			if($('body').hasClass(value + '-is-open')) activePopout = true;
		});
		return activePopout;
	}

	// Determine whether a popout is currently active
	function isPopoutActive(which) {
		var activePopout = false;
		if($('body').hasClass(which + '-is-open')) activePopout = true;
		return activePopout;
	}
	
	// Close all active popouts
	function closeActivePopouts() {
		$(popouts).each(function(index, value) {
			closeActivePopout(value);
		});
	}

	//Close selected popout
	function closeActivePopout(which) {

		if($('body').hasClass(which + '-is-open'))
		{
			$('body').removeClass(which + '-is-open');
		}

		var $el = $('#' + which);

		if(!$el.hasClass('popout-right')) {

			TweenMax.to($('#' + which), 0.4, {
				display: 'none',
				y: '-100%',
				onComplete: closeActivePopoutComplete,
				onCompleteParams: [which]
			});

		} else {

			TweenMax.to($('#' + which), 0.4, {
				display: 'none',
				x: '100%',
				onComplete: closeActivePopoutComplete,
				onCompleteParams: [which]
			});
			
		}

		TweenMax.to($('.popout-bg'), 0.4, {
			display: 'none',
			opacity: 0
		});


	}

	function closeActivePopoutComplete(which) {
		$('#' + which).removeClass('is-scrolled');
	}
	
	// Open a popout window
	function openPopout(which) {
		
		$('body').addClass(which + '-is-open');

		// No overflow support? Set a fixed height and scroll page to the top
		if ($('html').hasClass('touchevents'))
		{
			$('body').scrollTop(0);
		}

		var $el = $('#' + which);

		if(!$el.hasClass('popout-right')) {

			TweenMax.to($('#' + which), 0.4, {
				display: 'block',
				y: '0%'
			});

		} else {


			TweenMax.to($('#' + which), 0.4, {
				display: 'block',
				x: '0%'
			});
			
			TweenMax.to($('.popout-bg'), 0.4, {
				display: 'block',
				opacity: 1
			});
			
		}

	}

	var clickEventType = ((document.ontouchstart!==null)?'click':'touchstart'),
		popouts = [];

	$('.js-popout').each(function() {
		popouts.push($(this).attr('id'));
	});

	// Left button events
	$('.js-toggle-popout').on('click', function(e) {
		e.stopPropagation();
		e.preventDefault();

		$('.js-toggle-popout').removeClass('is-active');

		var popoutId = $(this).data('popout-id');

		if(!isPopoutActive(popoutId))
		{
			$(this).addClass('is-active');
			closeActivePopouts();
			openPopout(popoutId);
		}
		else
		{
			closeActivePopout(popoutId);
		}
		
	});


	$(document).on('click', '.js-close-active-popouts', function(e){
		closeActivePopouts();
	});

	// Ensure the popout menus handle their own touch events
	$('.popout').on('touchmove touchstart', function(e) {
		var ele = this,
			$ele = $(ele);
				
		// Ensure we don't scroll the page outside of the popout
		if (e.type == "touchstart")
		{
			if (ele.scrollTop === 0) {
				ele.scrollTop = 1;
			} else if (ele.scrollHeight === ele.scrollTop + ele.offsetHeight) {
				ele.scrollTop -= 1;
			}
		}
		
		if (e.type == "touchmove")
		{
			// No scrollbar? Then preventdefault to ensure we don't scroll outside
			if ($ele.get(0).scrollHeight < $ele.innerHeight())
				e.preventDefault();
		}
	});

	//show/hide fixed header depending on scroll posiiton
	$(window).scroll(function(e) {
		if($(this).scrollTop() > 116)
		{
			$('.mobile-header').addClass('is-scrolling');
		}
		else
		{
			$('.mobile-header').removeClass('is-scrolling');	
		}
	});
	
});