function attachSlick($el)
{
    $el.slick({
        autoplay        : $el.data('slick-autoplay') || false,
        autoplaySpeed   : $el.data('slick-autoplay-speed') || 4000,
        speed           : $el.data('slick-speed') || 600,
        slidesToShow    : $el.data('slick-slides-to-show') || 3,
        slidesToScroll  : $el.data('slick-slides-to-scroll') || 3,
        dots            : $el.data('slick-dots') || false,
        arrows          : $el.data('slick-arrows') || false,
        fade            : $el.data('slick-fade') || false,
        centerMode      : $el.data('slick-center-mode') || false,
        centerPadding   : $el.data('slick-center-padding') || '40px',
        useCSS          : $el.data('slick-use-css') || false,
        swipe           : $el.data('slick-swipe') || true,
        pauseOnHover    : $el.data('slick-pause-on-hover') || false,
        pauseOnFocus    : $el.data('slick-pause-on-hover') || false,
        vertical        : $el.data('slick-vertical') || false,
        verticalSwiping : $el.data('slick-vertical') || false,
        lazyLoad        : 'ondemand',
        responsive      : $el.data('slick-responsive') || [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
        ]
    });

}

$(function() {

    $('.js-carousel').each(function() {
        $carousel = $(this);
        attachSlick($carousel);
    });

    $('.js-carousel-mobile').each(function() {
        
        $carousel = $(this);

        if (Modernizr.mq('(max-width: 767px)')) {
            attachSlick($carousel);
        }

    });

});